.fan_purchase_banner {
	min-height: 46px;
	width: 100%;
	top: 66px;
	position: fixed;
	z-index: 102;
	background: linear-gradient(90deg, #f768ca 0%, #4e4bde 50%, #01b6df 100%);

	.payment_content {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		p {
			position: relative;
		}

		.progress_bar {
			padding: 5px 20px;
			background-color: #4c1f7a51;
			position: relative;

			&.completed {
				background-color: transparent;

				.progress {
					background-color: transparent;
				}
			}

			.progress {
				background-color: #4c1f7a;
				position: absolute;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
	}
}
