@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.locked_goscore_dialog {
	.dialog_paper {
		background-color: transparent;
		display: flex;
		flex-direction: column;
		height: 100%;
		align-items: center;
		justify-content: center;
		align-items: center;
		box-shadow: none;
		overflow: hidden;
		max-width: fit-content;

		.detail_dialog {
			width: 632px;
			flex-shrink: 0;
			border-radius: 15px;
			border: 1px solid rgba(33, 150, 243, 0.5);
			background: #0A1C2A;
			position: relative;

			@include media($phone_all...) {
				width: 100%;
			}

			.header {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				top: -77.5px;

				@include media($phone_all...) {
					top: -60px;
				}

				.top_image_continer {
					width: 304px;
					height: 155px;
					aspect-ratio: 2;
					margin: auto;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 20px;
					border: 1px solid rgba(1, 118, 255, 0.30);
					background: radial-gradient(233.44% 138.3% at 1.32% 3.1%, rgba(1, 118, 255, 0.24) 0%, rgba(1, 118, 255, 0.06) 100%);
					backdrop-filter: blur(10px);

					@include media($phone_all...) {
						width: 230px;
						height: 120px;
					}

					img {
						height: 65%;
						opacity: .25;

						&.is_on_perks_program {
							opacity: 1;
						}
					}
				}
			}

			.content {
				z-index: 10;
				padding: 15px 32px 32px;
				gap: 16px;
				display: flex;
				flex-direction: column;
				
				> div {
					align-items: center;
				}

				@include media($phone_all...) {
					padding: 20px 20px 20px;
				}
			}

			h1 {
				color: #FFF;
				
				font-family: Inter;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 28px;
				letter-spacing: -0.5px;


				@include media($phone_all...) {
					hyphens: none;
					line-height: 14px;
					font-size: 14px;
				}
			}

			p {
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 28px;
				color: #fff;

				span {
					color: #01b6df;
					font-weight: 600;
				}

				@include media($phone_all...) {
					hyphens: none;
					font-size: 12px;
					line-height: 20px;
				}
			}

			.controls {
				display: flex;
				justify-content: center;
				margin-top: 10px;

				button {
					display: inline-flex;
					height: 35px;
					padding: 5px 16px;
					justify-content: center;
					align-items: center;
					flex-shrink: 0;
					font-size: 13px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px;
					color: #000;
					text-align: center;
					background-color: #fff;
					border-radius: 6px;

					@include media($phone_all...) {
						font-size: 12px;
					}
				}
			}
		}
	}

}