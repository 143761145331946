@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.dialog {
	z-index: 1350 !important;

	.close_btn {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 101;
	}

	.dialog_paper {
		width: 350px;
		border-radius: 30px;
		background: #292375;

		.dialog_content {
			.notification_extended {
				display: flex;
				flex-direction: column;
				border-radius: 15px;
				position: relative;
				width: 100%;
				padding-bottom: 10px;

				.header_text {
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 18px;
					text-align: center;
					color: #ffffff;
				}

				.content_text {
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 18px;
					color: #ffffff;
				}

				.header {
					margin: 10px;
					position: relative;
					display: flex;
					width: 280px;
					min-height: 120px;
					justify-content: space-between;
					align-items: center;
					flex-direction: column;

					@include media($phone_all...) {
						margin: 0;
						max-width: 280px;
						width: 100%;
					}

					.adornment {
						width: 5px;
						height: 53px;
						background: #9664ff;
						border-radius: 0px 100px 100px 0px;
						position: absolute;
						left: 0;
						top: 54px;
					}

					h1 {
						@extend .header_text;
						font-size: 20px;
						font-weight: 700;
					}

					div {
						position: relative;
						display: flex;
						align-items: center;

						.avatar {
							border: 2px solid #fff;
						}

						.arrow {
							margin: 0 20px;
						}

						.icon_only {
							position: absolute;
							background: #0e7a46;
							border-radius: 50%;
							padding: 8px;
							font-size: 18px;
							top: 10px;
							left: 35px;
						}
					}

					p {
						@extend .header_text;
						margin: 0;
					}
				}

				.content {
					h3 {
						@extend .content_text;
						width: 100%;
						border-bottom: 1px solid #4e4bde;
						padding-left: 0px;
						padding-bottom: 4px;
						font-weight: 700;
					}

					.fees {
						color: #1aeff3;
						font-weight: 300;
					}

					div {
						display: flex;
						justify-content: space-between;
						padding: 6px 0;

						span {
							@extend .content_text;
							font-weight: 700;

							img {
								width: 10px;
								height: 10px;
								margin-right: 2px;
							}
						}

						&:last-child {
							padding-bottom: 15px;
							border-bottom: 1px solid #4e4bde;
						}
					}
				}

				.footer {
					div {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 12px 0;

						.fees {
							color: #1aeff3;
							font-weight: 300;
						}

						span {
							@extend .content_text;
							font-weight: 700;

							img {
								width: 10px;
								height: 10px;
								margin-right: 4px;
							}
						}

						p {
							font-size: 15px;
							font-weight: 600;
							line-height: 1rem;
							font-size: 13px;
							letter-spacing: 1px;
							color: #fff;
						}

						button {
							background: #eeeeee;
							border-radius: 10px;
							color: #000;
							font-weight: 400;
							height: 40px;
							width: 100px;
						}
					}

					.subinfo {
						background: #1e1d47;
						font-size: 12px;
						text-align: center;
						display: flex;
						line-height: 0.9rem;
						max-width: 320px;
						padding: 15px 15px;
						font-weight: 600;
						color: #fff;
						border-radius: 5px;
					}
				}
			}
		}
	}
}
