@import './components/common/FontsAll.scss';
@import '~include-media/dist/include-media';
@import 'MediaQuery.scss';

.diamond_bg {
	background: url('./assets/images/diamond_background.svg') no-repeat;
	position: fixed;
	width: 100vw;
	height: 1635px;
	overflow: hidden;
	top: 0;
	background-size: cover;
	background-position: 50% 38%;
}

.modal_dark {
	.MuiPaper-root {
		background: linear-gradient(180deg, #2e2bba 0%, #1e1d47 100%);
		border: 1px solid #2e2bba;
		border-radius: 30px !important;
		color: #fff !important;
		padding: 25px 35px;

		p {
			color: #fff;
		}

		button:nth-child(2) {
			min-width: 106px;
			height: 35px;
			border-radius: 25px;
			background: #4e4bde;
			margin-right: 12px;
			font-size: 0.875rem;
			font-weight: 500;

			&:hover {
				color: #000;
				background: #fff;
			}
		}
	}
}

w3m-modal {
	z-index: 1301;
}

p {
	overflow-wrap: break-word;
	word-wrap: break-word;
	white-space: normal;
	hyphens: auto;
}